// 通用自定义样式
@mixin multiLineEllipsis($lineCount: 3) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
  overflow: hidden;
  text-overflow: ellipsis;
}

// 使用混合宏并传入行数
.multi-line-ellipsis-2 {
  @include multiLineEllipsis(2);
}
