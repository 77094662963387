@font-face {
    font-family: my-app-icon;
    src: url("my-app-icon.woff2?80028150") format("woff2"),
             url("my-app-icon.woff?80028150") format("woff"),
             url("my-app-icon.ttf?80028150") format("truetype"),
             url("my-app-icon.svg?80028150#my-app-icon") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="my-icon"], [class*=" my-icon"] {
    font-family: 'my-app-icon';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
}


.my-icon-bookmark::before {
    content: "\554a";
}

.my-icon-calendar::before {
    content: "\554b";
}

.my-icon-state-beinvited::before {
    content: "\554c";
}

.my-icon-success::before {
    content: "\554d";
}

.my-icon-warning::before {
    content: "\554e";
}

