$baseColor: #eb3443;$ec:false;$emall:true;$pet:false;
@import "../iconfont/fonts/font.css";
@import "alicon/iconfont.css";
@import "alicon2/iconfont.css";
@import "alicon3/iconfont.css";
@import "leicon/iconfont.css";
@import "override.scss";
@import "defines.scss";
@import "flexible.scss";
@import "utils.scss";
@import "project.scss";
@import "iviewfix.scss";
@import "common.scss";

