html {
  font-size: 21vw;
}

* {
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

@media (min-width: 450px) {
  html {
    font-size: 550% !important;
  }
}

@media (min-width: 768px) and (max-width: 1299px) {
  html {
    font-size: 525% !important;
    // font-size: 8vw !important;
  }
}

@media (min-width: 1300px) {
  html {
    font-size: 625% !important;
  }
}

/*默认字体14px*/
body {
  font-size: 14px;
}

.el-table .cell {
  // overflow: visible !important;
  // overflow: hidden;
}

@media (max-width: 1299px) {
  html.is-printing-pdf {
    font-size: 625% !important;
    &::after {
      content: "正在导出PDF...";
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #333;
    }
    .pet-record-detail-custom-m-page .container {
      width: 969px !important;
    }
    .pet-record-detail-custom-pc-page .container {
      width: 969px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1299px) {
  body {
    width: 100vw;
    overflow-x: hidden;
    .layer-p-page {
      max-height: 100dvh;
      overflow: hidden;
      min-height: auto;
      .layer-container {
        width: 100vw;
      }
    }
    .right-gp {
      max-width: calc(100vw - 250px - 15px) !important;
      margin-left: calc(15px + 250px) !important;
    }
    .right-gp-maximize {
      max-width: calc(100vw - 125px - 15px) !important;
      margin-left: calc(15px + 125px) !important;
      transition: all 0.3s;
    }
    .right-gp.left-gp-hide {
      max-width: 100vw !important;
      margin-left: 0px !important;
    }
  }
  .el-date-editor{
    i{
      font-size: 15px!important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-range-separator{
      font-size: 14px!important;
    }
  }
}

@media (min-width: 1300px){
  body {
    .layer-p-page .layer-container .menu-container{
      height: auto!important;
    }
    .layer-p-page .layer-container .submenu-container{
      height: auto!important;
    }
    .right-gp-maximize {
      max-width: calc(932px + 125px) !important;
      transition: all 0.3s;
    }
    .right-gp.left-gp-hide {
      max-width: 1200px !important;
    }
  }
}


@media (min-width: 768px) {
  body {
    .layer-p-page .el-table .el-table__row .cell{
      line-height: 1.3!important;
    }
    //门店装修首页
    .shop-decoration-p-page .content-desc-ap {
      flex-direction: column;
    }
    .shop-decoration-p-page .container-1 > ul li {
      margin: 0px 5px;
    }
    //门店装修
    .shop-package-p-page .layout-attribute .content {
      width: 361px;
      .control {
        padding: 4px 12px;
      }
    }
    .shop-package-p-page .el-main .layout-tool .el-collapse {
      padding: 8px;
    }
    .el-header {
      height: 52px !important;
      .layout-event{
        height: 50px !important;
      }
      .layout-back{
        height: 50px !important;
        ul{
          height: 50px !important;
          line-height: 50px !important;
          li{
            height: 50px !important;
            line-height: 50px !important;
          }
        }
      }
    }
    //左侧栏
    .layer-p-page .menu-container .menu-item {
      padding: 4px 8px;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    .layer-p-page .submenu-item {
      text-align: center;
      padding: 4px 8px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .layer-p-page .submenu-container {
      padding: 0px;
      border-left: 0px;
    }
    //箭头
    .top-bar-item.actived::after {
      transform: translateX(-50%) translateY(calc(-50% + 2px)) !important;
    }
    //搜索栏
    .layer-p-page .menu-container {
      padding-top: 0px;
      border-right: 0px;
    }
    .search-top {
      padding-top: 16px !important;
      .top-group {
        margin-bottom: 10px !important;
      }
    }
    .el-dialog__body{
      .search-top {
        padding-top: 10px !important;
      }
    }
    .advance-search-pc {
      .btn-group,
      .advance-search-item {
        margin-bottom: 10px !important;
      }
    }
    .after-search-block {
      margin-bottom: 10px !important;
    }
    .layer-p-page .shopper-header .header-top {
      margin-top: 20px;
    }
    //概况
    .general-p-page .data-total .inner ul li {
      width: calc(100% / 4 - 10px);
    }
    .general-p-page .data-total .inner ul li p {
      font-size: 30px;
    }
    //订单block
    .handle-group-container {
      .hd-item {
        margin: 5px 0;
      }
    }
    //appointmentBoard
    .appointment-board-p-page
      .table-inner
      .el-table
      thead
      tr:first-of-type
      th:first-of-type:before {
      right: 10%;
    }
    //商品
    .service-list-p-page .pd-name .pd-img {
      display: none;
    }
    .cardlist-layer-p-page .cd-name .cd-type-icon {
      display: none;
    }
    .product-list-p-page .pd-name .pd-img {
      display: none;
    }
    .stock-manage-p-page .list-inner .st-name .hd-wrapper {
      display: none;
    }
    //stafflist
    .stafflist-p-page .el-table__row td .cell {
      text-align: center !important;
    }
    .advance-search-pc {
      flex: 1;
      .el-button{
        width: auto!important;
      }
    }
  }
  .el-form-item{
    .el-form-item__label{
      line-height: 32px;
    }
    .el-form-item__content{
      line-height: 32px;
      min-height: 32px;
    }
  }
  .el-form-item__content{
    display: flex;
    align-items: center;
  }
  .el-form-item__error{
    position: static;
    margin-left: 3px;
    padding-top: 0px;
  }
  .el-dialog__body{
    display: flex;
    flex-direction: column;
  }
}

.right-gp .sticky-header {
  position: sticky;
  top: 0px;
  z-index: 10;
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 0 0 10px 0 #f5f5f5;
  padding: 10px 15px;
}

.bread-top{
  display: flex;
  align-items: center;
}

.column-item-wrapper{
  .el-form-item__content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}