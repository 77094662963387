// 项目的公共样式 避免直接修改mobile的vue

.global-mask {
  background: transparent;
}

// html{
//   overflow-y: scroll;
// }

// :root {
//   overflow-x: auto;
//   overflow-y: hidden;
// }

// :root body {
//   position: absolute;
// }

// body {
//   width: 100vw;
//   overflow: hidden;
// }

.p-page {
  min-width: calc(100% - 30px);
  // min-height: calc(100% - 30px);
  // border: 1px solid #ddd;
  // padding: 20px;
  overflow-y: auto;
}

.el-dropdown-menu {
  .el-dropdown-menu__item {
    padding: 0px 40px;
  }
}

.m-page {
  width: 100%;
  min-height: 100%;
  padding: 15px 7px 0px 7px;

  &.general-m-page {
    .top-title {
      flex-wrap: wrap;
      height: auto !important;
      padding-bottom: 10px !important;
      padding-top: 10px !important;

      .title-right {
        & > span {
          display: none;
        }

        .el-date-editor {
          width: 100%;
        }
      }
    }

    .common-func .inner {
      ul li {
        float: none;
        flex-direction: row;
        width: calc(100% - 20px);
        height: 90px;
        justify-content: flex-start;
        padding-left: 30px !important;
        p {
          font-size: 20px !important;
          margin-top: 0px !important;
        }
        .icon-wrapper {
          height: 70px;
          width: 70px;
          margin-right: 10px;
        }
      }
    }

    li {
      padding: 0px !important;
      text-align: center;

      p {
        font-size: 27px !important;
      }
    }

    h4 {
      padding: 0px !important;
      font-size: 16px !important;
    }

    span {
      white-space: nowrap;
    }
  }

  .advance-search-pc {
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &.stafflist-layer-m-page {
    .btn-group {
      flex-wrap: wrap;
    }

    .el-input {
      min-width: 100px;
      max-width: 100%;
    }

    .staff-info-inner {
      flex-wrap: wrap;
    }

    .header {
      margin-bottom: 20px;
    }

    .char-info-2 {
      margin: 0px !important;
      margin-top: 20px !important;
    }
  }

  &.work-attendance-m-page {
    .btn-group {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &.month-summary-m-page {
    .btn-group {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &.appointment-detail-m-page {
    .base-info {
      .item-block {
        width: 100%;
        flex: auto !important;
      }
    }

    .item-block {
      flex: auto !important;
      flex-wrap: wrap;
    }

    .inner {
      flex-wrap: wrap;
    }

    .el-table {
      max-width: 100%;
      width: 100% !important;

      .cell {
        white-space: nowrap;
      }
    }
  }

  &.appointment-board-m-page {
    .name {
      white-space: nowrap;
    }

    .status-group {
      // flex-wrap: wrap;
      display: flex;
    }

    .btn-group {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;

      .el-date-editor {
        // margin-left: 0px !important;
        // margin-top: 10px;
      }
    }
    .status-group .status-block + .status-block {
      margin-left: 0px !important;
    }
    .status-block .name {
      margin-right: 5px;
      margin-left: 2px !important;
    }
  }

  &.appointment-setting-m-page {
    .top-block {
      flex-wrap: wrap;
      height: auto !important;
      padding: 20px 20px;
    }

    .el-radio-group label {
      height: auto !important;
      padding: 10px 0;
    }

    .el-select {
      .el-input {
        width: 100px !important;
      }
    }

    .el-radio__label {
      display: flex;
      flex-wrap: wrap;
      line-height: 22px;
    }

    span {
      white-space: normal !important;
      word-wrap: break-word;
    }
  }

  &.service-list-m-page {
    .btn-group {
      flex-wrap: wrap;

      .el-button {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }

  &.card-list-m-page {
    .cd-info-name,
    .cd-price {
      white-space: nowrap;
    }

    .cd-type-icon {
      min-width: 48px;
    }
  }

  &.product-list-m-page {
    .btn-group {
      flex-direction: column;
      align-items: flex-start;

      .el-button {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }

  &.stock-manage-m-page {
    .top-group {
      flex-wrap: wrap;
      .tg-left {
        .el-button:nth-of-type(2) {
          margin-left: 0px;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &.stock-detail-m-page {
    .row-label {
      white-space: nowrap;
    }
    .row-val {
      word-break: break-all;
    }
  }

  &.order-list-m-page {
    .top-sec {
      flex-wrap: wrap;
      .more-top {
        margin-top: 10px;
      }
    }
    .sec-label {
      white-space: nowrap;
    }
    .sec-item {
      width: 100% !important;
    }
    .status-tab-item {
      overflow: hidden;
    }
    .order-time {
      flex-wrap: wrap;
    }
  }

  &.add-customer-m-page {
    .el-form-item__label {
      width: 120px !important;
    }
  }

  &.customer-detail-m-page {
    .top-header {
      flex-wrap: wrap;
    }
    .name {
      word-break: break-all;
      width: 100%;
      padding: 0 10px;
      margin: 10px 0;
    }
    .label-name {
      font-size: 16px !important;
      line-height: 22px !important;
      padding: 0 5px;
    }
    .single-wrapper {
      flex-direction: column;
      .single-item {
        border-left: 0px !important;
      }
    }
    .label-name {
      min-height: 50px;
      text-align: center;
    }
    .desc-more {
      display: none;
    }
  }

  &.common-setting-m-page {
    .item-val {
      flex-wrap: wrap;
    }
  }

  &.shop-setting-m-page {
    .block-inner-label {
      text-align: left !important;
      width: 100px;
      max-width: 100px;
    }
    .el-input {
      width: 100px !important;
    }
  }

  &.shop-list-m-page {
    .user-info {
      flex-wrap: wrap;
      height: auto;
      padding-bottom: 20px;
      padding-top: 20px;
    }
    .user-info-inner {
      .header {
        width: 60px;
        height: 60px;
      }
    }
    .notice .a-link {
      display: inline;
    }
    .notice {
      display: block !important;
      margin-top: 10px;
    }
    .list-item {
      max-width: 100%;
    }
    .shop-list-inner {
      padding-right: 32px;
    }
    .inner-top {
      flex-wrap: wrap;
    }
  }

  .time-select-button {
    display: flex;
    margin-bottom: 20px;
  }

  .after-search-block {
    flex-wrap: wrap;
  }

  .el-date-editor--datetimerange.el-date-editor input {
    width: 100% !important;
  }

  .advance-search-item {
    max-width: 100%;
  }

  .el-pagination {
    max-width: 100%;
  }

  .el-form-item__content {
    .el-input,
    .el-select,
    .el-textarea {
      width: 100% !important;
    }
  }
}

.el-popover {
  max-width: 100%;
}

.el-dialog__wrapper{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .el-dialog__header{
    flex-shrink: 0;
  }
  .el-dialog__body{
    flex: 1;
  }
  justify-content: center;
}

.el-dialog {
  max-width: 90%;
  margin-top: 0 !important;
  margin: auto !important;
  // top: 50% !important;
  // transform: translateY(-50%) !important;
  max-height: 96dvh;
  display: flex;
  flex-direction: column;
  .el-dialog__header{
    flex-shrink: 0;
    padding: 10px 15px;
    padding-bottom: 0px;
  }
  .el-dialog__body{
    overflow-y: auto;
    flex: 1;
  }

  .el-form-item__content {
    .el-input,
    .el-select,
    .el-textarea {
      max-width: 100% !important;
    }
  }
}

//element-ui样式调整
.el-input-width-100 {
  .el-form-item {
    width: 100%;
  }
}

.el-dialog-padding-30 {
  // .el-dialog__body {
  //   padding: 30px 40px;
  // }
  .el-dialog__body {
    padding: 10px 15px;
  }
}

.el-dialog-padding-0 {
  .el-dialog__body {
    padding: 0px;
    padding-top: 0px !important;
  }
}

.el-dialog-padding-15 {
  .el-dialog__body {
    padding: 15px 20px;
  }
}

.el-dialog-title-center {
  .el-dialog__header {
    text-align: center;
  }
}

.el-message-box {
  max-width: 100%;
}

.el-message-box__wrapper {
  .el-message-box__btns button:nth-child(1) {
    float: right;
    margin-left: 10px;
  }
}

.el-calendar__header {
  justify-content: flex-end;

  & > .el-calendar__title {
    margin-right: 10px;
  }
}

.el-calendar__title {
  font-size: 17px;
}

.el-table {
  .el-table__header-wrapper {
    th.is-left {
      // text-align: center;
      text-align: left;
    }
  }
}

//移动端element-ui样式调整
// .m-dialog {
//   .el-dialog {
//     width: 96% !important;
//     margin-top: 80px !important;

//     .el-form-item__label {
//       white-space: nowrap;
//     }
//   }
// }

//移动端响应式布局
@media screen and (max-width: 768px) {
  .el-dialog {
    width: 96% !important;
    margin-top: 80px !important;

    .el-form-item__label {
      white-space: nowrap;
    }
  }
}

.m-page {
  .el-button {
    padding: 0 15px;
    height: 40px;
  }
}

.m-dialog-date {
  .el-calendar__title {
    font-size: 15px;
  }

  .el-dialog {
    width: 96% !important;
    margin-top: 3vh !important;

    .el-form-item__label {
      white-space: nowrap;
    }
  }

  .el-dialog__body {
    padding: 10px;
  }

  .el-calendar__body {
    padding: 0px;
  }
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-25px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(25px);
}

.drop-transform-leave-active,
.drop-transform-enter-active {
  transition: all 0.3s;
}

.drop-transform-enter {
  opacity: 0;
}

.drop-transform-leave-to {
  opacity: 0;
}

/*滚动条样式*/
::-webkit-scrollbar {
  display: none;
}

// ::-webkit-scrollbar {
//   border-radius: 8px;
//   width: 8px;
//   height: 8px;
// }

// ::-webkit-scrollbar-track {
// 	border-radius: 8px;
// 	-webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
// 	background: linear-gradient(90deg, #efefef 0%, #fff 78%, #efefef 100%);
// }

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(90deg, #efefef 0%, #fff 78%, #efefef 100%);
}

::-webkit-scrollbar-thumb:vertical {
  background: linear-gradient(0deg, #efefef 0%, #fff 78%, #efefef 100%);
}

.record-sp-conatiner-pc {
  .table-row-text {
    font-size: 15px;
    white-space: pre-wrap;
  }

  .sp-record-titie {
    font-size: 16px;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .sp-record-titie:nth-of-type(1) {
    margin-top: 0px;
  }
}

.record-sp-conatiner-m {
  .table-row-text {
    font-size: 15px;
    white-space: pre-wrap;
  }

  .sp-record-titie {
    font-size: 16px;
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .sp-record-titie:nth-of-type(1) {
    margin-top: 0px;
  }
}

.eval-show-conatiner-pc {
  .eval-item {
    margin-bottom: 20px;

    & > span {
      font-size: 15px;
    }

    .eval-star {
      display: flex;
      align-items: center;
      font-size: 15px;

      & > span {
        display: inline-block;
        width: 60px;
        vertical-align: middle;
        transform: translateY(-1px);
      }
    }
  }
}

.tutor-serve-summary {
  .ql-container.ql-snow {
    height: 500px;

    .ql-editor {
      p,
      li {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.row-btn-fix {
  vertical-align: middle;
  margin-left: 4px;
}

.row-btn-fix.el-button--small {
  height: 33px;
  line-height: 5px;
}

.m-page .row-btn-fix.el-button--small {
  height: 40px;
  line-height: 6px;
}

.el-tree-node__label {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #444444;
}

.shop-setting-header-tab-p {
  display: flex;
  width: 100%;

  .tab-item {
    flex: 1;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background: #ffffff;
    color: #444444;
    cursor: pointer;
    transition: all 0.2s;
  }

  .tab-item.actived {
    color: #ffffff;
    background: $baseColor;
    position: relative;
  }

  .tab-item.actived::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border: 10px solid $baseColor;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
}

.le-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.cd-type-icon {
  position: relative;
  width: 48px;
  height: 40px;
  border-radius: 4px;
  padding-top: 14px;
  color: #fff;
  font-weight: bold;
  margin-right: 12px;
  user-select: none;
}

.cd-type-icon::after {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: 0px;
  width: 100%;
  height: 4px;
  background: #fff;
}

.cd-type-1 {
  background: #ffb320;
}

.cd-type-2 {
  background: #00b7f3;
}

.cd-type-3 {
  background: #ff2093;
}

.cd-type-4 {
  background: rgb(255, 102, 1);
}

.el-message {
  max-width: 95vw;
  min-width: 3.3rem;

  .el-message__content {
    line-height: 20px;
  }
}

.el-table-fixed {
  height: auto !important;
}

.appointment-board-p-page {
  // .el-table--scrollable-x .el-table__body-wrapper{
  //   transform: scaleY(-1);
  // }
  // .el-table--scrollable-x .el-table__body-wrapper table{
  //   transform: scaleY(-1);
  // }
  .el-table__body-wrapper::-webkit-scrollbar {
    display: block;
  }
}

.el-table__fixed,
.el-table__fixed-right {
  pointer-events: none;
  td {
    pointer-events: auto;
  }
}

.my-popper {
  min-width: 300px !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.base-item-block {
  padding: 20px;
  background: #fff;
  margin-bottom: 14px;
}

//平板端
.layer-tt-page .top-bar .top-bar-container {
  padding: 0 20px;
}

.order-list-tt-page {
  .sec-item {
    width: 50% !important;
    .sec-label {
      flex-shrink: 0;
    }
  }
}

//bubble
.filter-blur {
  filter: blur(6px);
}

//移动端适配
.m-page {
  .advance-search-pc {
    .el-button {
      min-width: 120px;
    }
  }
  .order-block-p-component {
    .time-tc {
      display: block !important;
    }
    .order-num {
      padding-left: 0px !important;
    }
    .order-hd-1 {
      white-space: nowrap;
    }
  }
  .order-shop-block-p-component {
    .time-tc {
      display: block !important;
    }
    .order-num {
      padding-left: 0px !important;
    }
    .order-hd-1 {
      white-space: nowrap;
    }
    .odt-more{
      width: 100%;
      margin-top: 10px;
      justify-content: flex-end;
    }
  }
}

.top-sec{
  flex-shrink: 0;
}


//适配英文版
.el-table .cell{
  word-break: normal !important;
}

.layer-p-page .el-table .el-button--mini{
  padding: 3px 3px;
  min-width: 55px;
  text-align: center;
}